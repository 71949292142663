import React from 'react';
import {
  Form,
  useActionData,
  useFetcher,
  useLocation,
  useNavigate,
  useNavigation,
  // useRouteLoaderData,
} from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

export interface AuthProvider {
  username: null | string;
}

export const AuthStatus = () => {
  // Get our logged in user, if they exist, from the root route loader data
  const user = sessionStorage.getItem('accessToken');
  // let { user } = useRouteLoaderData('root') as {
  //   user: { username: string } | null;
  // };
  let fetcher = useFetcher();
  const navigate = useNavigate();

  if (!user) {
    return (
      <div>
        <p>You are not logged in.</p>
        <button type="submit" onClick={() => navigate('/login')}>
          Login
        </button>
      </div>
    );
  }

  const decoded: any = jwtDecode(user);
  console.log('decoded', decoded);
  let isLoggingOut = fetcher.formData != null;

  return (
    <div>
      <p>Welcome {decoded?.username ?? ''}!</p>
      <fetcher.Form method="post" action="/logout">
        <button type="submit" disabled={isLoggingOut}>
          {isLoggingOut ? 'Signing out...' : 'Sign out'}
        </button>
      </fetcher.Form>
    </div>
  );
};

export const LoginPage = () => {
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  let from = params.get('from') || '/';

  let navigation = useNavigation();
  let isLoggingIn = navigation.formData?.get('username') != null;

  let actionData = useActionData() as { error: string } | undefined;

  return (
    <div>
      <p>You must log in to view the page at {from}</p>

      <Form method="post" replace>
        <input type="hidden" name="redirectTo" value={from} />
        <label>
          Username: <input name="username" />
        </label>{' '}
        <button type="submit" disabled={isLoggingIn}>
          {isLoggingIn ? 'Logging in...' : 'Login'}
        </button>
        {actionData && actionData.error ? (
          <p style={{ color: 'red' }}>{actionData.error}</p>
        ) : null}
      </Form>
    </div>
  );
};
