import { Link, Outlet } from 'react-router-dom';
import { AuthStatus } from '../auth';
import './SiteWrapper.css';

export const SiteWrapper = () => {
  return (
    <div className="grid wrapper">
      <div className="col-12 xl:col-8 xl:col-offset-2" style={{ padding: 0 }}>
        <Link to="/" className="head">
          <h1 style={{ margin: 0 }}>slime center</h1>
        </Link>

        <AuthStatus />
        <Outlet />

        <div className="footer">
          <span>
            &copy;{' '}
            <a
              href="https://meltknuckles.info/"
              target="_blank"
              rel="noreferrer"
            >
              meltknuckles
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};
