import {
  createBrowserRouter,
  LoaderFunctionArgs,
  redirect,
  RouterProvider,
} from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import { SiteWrapper } from '../../components/SiteWrapper';
import { Home } from '../Home/Home';
import { Game } from '../Game/Game';

import 'primereact/resources/themes/saga-green/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.css';
import { AuthProvider } from '../../auth';
import { useLocalStorage } from '@uidotdev/usehooks';
import LoginPage from '../LoginPage';
import ConfirmUserPage from '../ConfirmUserPage';

const App = () => {
  const [user, setUser] = useLocalStorage<AuthProvider | null>('user', null);
  const isAuthenticated = () => {
    const accessToken = sessionStorage.getItem('accessToken');
    return !!accessToken;
  };

  const protectedLoader = ({ request }: LoaderFunctionArgs) => {
    if (!isAuthenticated()) {
      let params = new URLSearchParams();
      params.set('from', new URL(request.url).pathname);
      return redirect('/login?' + params.toString());
    }
    return null;
  };
  const router = createBrowserRouter([
    {
      id: 'root',
      path: '/',
      loader() {
        return { user };
      },
      Component: SiteWrapper,
      children: [
        {
          index: true,
          Component: Home,
          // element: <Home />,
        },
        {
          path: 'login',
          // action: async ({ request }: LoaderFunctionArgs) => {
          //   let formData = await request.formData();
          //   let username = formData.get('username') as string | null;
          //   if (!username) {
          //     return {
          //       error: 'You must provide a username to log in',
          //     };
          //   }

          //   try {
          //     // await fakeAuthProvider.signin(username);
          //     setUser({ username });
          //   } catch (error) {
          //     // Unused as of now but this is how you would handle invalid
          //     // username/password combinations - just like validating the inputs
          //     // above
          //     return {
          //       error: 'Invalid login attempt',
          //     };
          //   }

          //   let redirectTo = formData.get('redirectTo') as string | null;
          //   return redirect(redirectTo || '/');
          // },
          loader: () => {
            if (isAuthenticated()) {
              return redirect('/');
            }
            return null;
          },
          Component: LoginPage,
        },
        {
          loader: protectedLoader,
          path: 'game/:name',
          Component: Game,
          // element: <Game />,
        },
        {
          path: 'confirm',
          Component: ConfirmUserPage,
        },
      ],
    },
    {
      path: '/logout',
      async action() {
        // We signout in a "resource route" that we can hit from a fetcher.Form
        setUser(null);
        sessionStorage.clear();
        return redirect('/');
      },
    },
    //   {
    //     path: '/',
    //     element: <Home />,
    //   },
    //   {
    //     path: '/game/:name',
    //     element: <Game />,
    //   },
    //   {
    //     path: '/login',
    //   },
    // ].map((route) => ({
    //   ...route,
    //   element: <SiteWrapper>{route.element}</SiteWrapper>,
    // })),
  ]);
  return (
    <PrimeReactProvider>
      <div className="App">
        <RouterProvider
          router={router}
          fallbackElement={<p>Initial Load...</p>}
        />
      </div>
    </PrimeReactProvider>
  );
};

export default App;
