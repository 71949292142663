import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GAMES } from '../../constants';
import './Game.css';

export const Game = ({ gameOverride }: { gameOverride?: string }) => {
  const { name } = useParams();
  const gameName = gameOverride ?? name ?? '';

  const [game] = useState({ ...GAMES[gameName], name: gameName });
  const navigate = useNavigate();
  useEffect(() => {
    if (!game || !Object.keys(GAMES).includes(game.name ?? '')) {
      navigate('/');
    }
  }, [game, navigate]);
  const { width, height } = GAMES[gameName] ?? { width: 0, height: 0 };

  return (
    <div className="page" style={{ textAlign: 'center' }}>
      <h1 style={{ marginTop: 0 }}>{game.title}</h1>

      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        <iframe
          title={gameName}
          src={`${process.env.PUBLIC_URL}/games/${gameName}/index.html`}
          width={width}
          height={height}
          loading="eager"
          sandbox="allow-scripts allow-same-origin allow-top-navigation"
          style={{ borderRadius: 25, border: 'none' }}
        ></iframe>
      </div>
    </div>
  );
};
