export const GAMES: Record<
  string,
  { title: string; width: number; height: number }
> = {
  'dress-up': {
    title: 'slime dress-up',
    width: 964,
    height: 490,
  },
  cooking: {
    title: 'slime cooking',
    width: 964,
    height: 490,
  },
  map: {
    title: '',
    width: 960,
    height: 540,
  },
};
